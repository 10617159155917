

$split_2_cta_switch_width: 800px;


.split-2-cta{
    .split{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        //min-height: 700px;

        >.container{
            //flex: 0 0 50%;
            flex: 1 1 50%
        }
        .padding-wrapper{
            width: 100%;
        }
        @media screen and (max-width: $split_2_cta_switch_width){
            flex-wrap: wrap;
            >.container{
                flex: 0 0 100%;
            }
        }
    }
    .text-container{
        .content-width{
            padding-right: $spacing-normal;
            height: 100%;
        }
        .text{
            height: 100%;
        }
        .padding-wrapper{
            padding-top: $spacing-large;
            padding-bottom: $spacing-large;
            padding-right: $spacing-normal;
        }
        h3{
            @include font-thin_heading;
        }
        strong{
            font-weight: $font_weight-semi-bold;
            color: $c-light_blue;
        }
        @media screen and (max-width: $split_2_cta_switch_width){
            .content-width{
                padding-left: $spacing-tiny !important;
                padding-right: $spacing-tiny !important;
                padding-top: $spacing-normal !important;
                padding-bottom: $spacing-normal !important;
                margin: auto !important;
            }
            .padding-wrapper{
                padding: 0 !important;
            }

        }
    }
    .image-container{
        height: auto;

        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        /*
        .content-width-huge{
            height: 100%;
            padding: 0;
        }
        .image{
            height: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        */
        @media screen and (max-width: $split_2_cta_switch_width){
            height: 400px;
        }

    }
}
.split-2-cta.image-left{
    .split{
        flex-direction: row-reverse;
        .content-width{
            &.left{
                margin-left: 0;
                margin-right: auto;
            }
            &.right{
                margin-right: 0;
                margin-left: auto;
            }
        }
    }
    .text-container{
        .content-width{
            padding-right: $spacing-tiny;
            padding-left: $spacing-normal;
        }
        .padding-wrapper{
            padding-right: 0;
            padding-left: $spacing-normal;
        }
    }
}
