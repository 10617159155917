.image-content-bar{
    margin-top: $spacing-small;
    margin-bottom: $spacing-small;
    .wrapper{
        display: flex;
        justify-content: center;
        align-items: center;

        background-position: center;
        background-size: contain;
        .content{
            margin: 0;
            h3{
                @include font-thin_heading;
                text-align: center;
                padding: $spacing-large $spacing-normal;
            }
        }
    }
}
