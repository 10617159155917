body:not(.page-template-template-front-page){
    display: none;
}

#preloader{
    display: none;
}
.page-template-template-front-page #preloader{
    z-index: 9999;

    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;

    pointer-events: none;

    .background,
    .foreground{
        pointer-events: all;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateX(0%);
        transition: transform 1s;
    }
    .background{
        background-color: #fff;
    }
    .foreground{
        background-color: $c-black;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .wrapper{
            width: 200px;
            img{
                width: 100%;
                height: auto;
            }
        }
    }

    &.fire-fg{
        .foreground{
            transform: translateX(100%);
        }
    }
    &.fire-bg{
        .background{
            transform: translateX(100%);
        }
    }

    //animation
    svg{

        .letter-O {
            stroke-dasharray: 175;
            stroke-dashoffset: 175;
        }
        .letter-N {
            stroke-dasharray: 180;
            stroke-dashoffset: 180;
        }
        .letter-V {
            stroke-dasharray: 175;
            stroke-dashoffset: 175;
        }



    }
    &.text-anim-go{
        svg{
            .letter-O {
                animation: dash-O 2s linear;
                animation-fill-mode: forwards;
            }
            .letter-N {
                animation: dash-N 2s linear;
                animation-fill-mode: forwards;
            }
            .letter-V {
                animation: dash-V 2s linear;
                animation-fill-mode: forwards;
            }
            @keyframes dash-O {
              from {
                stroke-dashoffset: 175;
              }
              to {
                stroke-dashoffset: 0;
              }
            }
            @keyframes dash-N {
              from {
                stroke-dashoffset: 180;
              }
              to {
                stroke-dashoffset: 0;
              }
            }
            @keyframes dash-V {
              from {
                stroke-dashoffset: 175;
              }
              to {
                stroke-dashoffset: 0;
              }
            }
        }
    }

}
/*
.page-loaded #preloader{
    //display: flex;

    transition: transform 1s cubic-bezier(.47,0,.74,.71);
    transition-delay: .85s;

    transform: translateX(-100%);
    .animation-wrapper{
        transition: transform .5s ease;
        transition-delay: .5s;

        transform: translateX(-5%);
    }
}
*/
