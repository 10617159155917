@import 'reset';
@import 'colors';
@import 'typography';
@import 'layout';
@import 'buttons';


//https://www.youtube.com/watch?v=5c5kWnqNAUk


html{
  @include font-default;
  color: #000;
}

body{

}



@import 'footer';



//Minis
//@import 'template-parts/minis/ssss'
@import 'template-parts/minis/floating-logo-bar';



//Template Parts:
//@import 'template-parts/ssss';
@import 'template-parts/preloader';
@import 'template-parts/homepage-slider';
@import 'template-parts/header-menu';
@import 'template-parts/landing-intro';
@import 'template-parts/product-bar-4';
@import 'template-parts/full_width_video-cta';
@import 'template-parts/full_width-cta';
@import 'template-parts/latest-news';
@import 'template-parts/sliced-cta';
@import 'template-parts/call-us-cta';
@import 'template-parts/contact_form';
@import 'template-parts/testimonial-slider-bar';
@import 'template-parts/logo-bar';
@import 'template-parts/mega-menu';
@import 'template-parts/title-bar';
@import 'template-parts/split-2-cta';
@import 'template-parts/content-section';
@import 'template-parts/image-title-bar';
@import 'template-parts/testimonial-split-content';
@import 'template-parts/team-slider';
@import 'template-parts/meet-the-team-3';
@import 'template-parts/image-content-bar';


//Page Tweaks
@import 'page-tweaks/contact';
@import 'page-tweaks/result-section';
@import 'page-tweaks/testimonials-main';
@import 'page-tweaks/blog-main';
@import 'page-tweaks/search';
@import 'page-tweaks/claims';
@import 'page-tweaks/page_404';



//TWeakz

body>#page{
    margin-top: $sticky-menu-height;
}

/*******************************************************************************
Privacy Page GDPR Update 180523
*******************************************************************************/
#gdpr *{  }
#gdpr p{  }
#gdpr a{  }
#gdpr li{ line-height:160%; }
#gdpr table{ width:100%; }
#gdpr table{ margin-bottom:20px; border-collapse:collapse; }
#gdpr table tr{  }
#gdpr table th{ padding:5px 10px; border:#333 solid 1px; background: #666; color:#fff !important; }
#gdpr table th p{ color:#fff !important; }
#gdpr table td{ padding:5px 10px; border:#333 solid 1px; vertical-align: top; }
#gdpr table p{ padding:0; margin:0; line-height: 160%; }



//Privacy page formatting
#main-text-page{
    @include user_input_area;
    padding-top: $spacing-normal;
    padding-bottom: $spacing-normal;
    a{
        transition: color 0.5s;
        &:hover{
            color: $c-light_blue;
        }
    }
}












/*
*,
*:before,
*:after{
    border: red 1px solid;
    outline: red 1px solid;
}
*/
