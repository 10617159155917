.title-bar{
    .wrapper{
        height: 500px;
        background-size: cover;
        background-position: center;
    }
    .content-width{
        height: 100%;
    }
    h1{
        @include font-thin_heading-big;
        font-family: $font-menu;
        text-transform: uppercase;
        //margin-top: 40px;
        color: $c-white;
    }
}
.title-bar-map{
    iframe{
        width: 100%;
        height: 400px;
        display: block;
        filter: grayscale(100);
    }
}


@media screen and (max-width: 800px){
    .title-bar{
        .wrapper{
            height: 400px;
        }
    }
}
@media screen and (max-width: 700px){
    .title-bar{
        .wrapper{
            height: 300px;
        }
    }
}
@media screen and (max-width: 600px){
    .title-bar{
        .wrapper{
            height: 250px;
        }
    }
}
