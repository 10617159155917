#homepage-slider-container{
    $height: 85vh;
    height: $height;
    width: 100%;
    position: relative;
    overflow: hidden;



    ul{
        list-style: none;
        padding-left: 0;
    }



    .slider,
    .slider-overlay{

    }
    .slider{
        z-index: 1;
        position: relative;
    }
    .slider-overlay{
        position: absolute;
        top: 0;
        left: -10vw;
        right: -13vw;
        bottom: 180px;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        span{
            display: block;
            font-size: 48.5vw;
            width: 100%;
            color: $c-white;
            text-align: center;
            font-weight: 200;
            transform: rotateX(40deg);
            line-height: .72;
        }
        img{
            width: 100%;
            height: auto;
        }

        @media screen and (max-width: 700px){
            display: none;
        }
    }
    .slider-dots-overlay{
        position: absolute;
        top: 0;left: 0;right: 0;bottom: 0;
        z-index: 2;
        pointer-events: none;
        .content-width{
            height: 100%;
            position: relative;
            @media screen and (max-width: 700px){
                display: flex;
                justify-content: center;
                align-items: flex-end;
            }
        }

        ul{
            position: absolute;
            bottom: 50px;
            right: 9px;
            width: auto;
            @media screen and (max-width: 700px){
                position: static;
                margin-bottom: $spacing-small;
            }
            li{
                float: left;
                padding-left: 20px;

                &:first-child{
                    padding-left: 0;
                }
                button{
                    cursor: pointer;
                    border: 0;
                    outline: 0;
                    background-color: transparent;
                    color: transparent;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    border: 2px solid $c-white;
                    transition: background-color 0.5s;
                    pointer-events: auto;

                    //apple user agent styling fix
                    padding: 0;
                    &:hover{
                        background-color: $c-grey;
                    }
                }
                &.slick-active button{
                    background-color: $c-white;
                }
            }
        }

    }
    .slide{
        height: $height;
        position: relative;
    }
    .background{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        .slide{
            @include hero-background-image-overlay;
            background-size: cover;
            background-position: center;
        }

    }
    .foreground{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        z-index: 10;
        .content-area{
            position: absolute;
            bottom: 0;
            width: 100%;
            color: $c-white;
            padding-bottom: $spacing-normal;
            @media screen and (max-width: 700px){
                padding-bottom: $spacing-large;
            }

            .content-width{


                display: flex;
                flex-direction: row;
                justify-content: space-between;

                padding-left: $spacing-tiny;
            }
            .left{
                @include font-thin_heading;

                padding-left: $spacing-normal;

                border-left: $c-white solid 1px;
                @media screen and (max-width: 700px){
                    font-size: 17pt;
                    padding-left: $spacing-small;
                }

                h3{
                    @include font-slider_heading;
                    @media screen and (max-width: 700px){
                        font-size: 26pt;
                    }
                }
                p{
                    margin: 0;
                }
            }
            .right{
                >.padding-wrapper{
                    margin-top: 15px;
                    position: relative;
                    height: 100%;
                }
                .top{

                }
                .bottom{
                    position: absolute;
                    bottom: 0;


                }
            }
            @media screen and (max-width:800px) {
                .content-width{
                    flex-wrap: wrap;
                }
                .left,
                .right{
                    flex: 0 0 100%;
                }
                .right .bn-button{
                    margin-left: $spacing-normal;
                }
            }
            @media screen and (max-width: 700px){
                .right .bn-button{
                    margin-left: $spacing-small;
                }
            }
        }
    }
}
