

$result_flickover_width: 550px;



.filter-slider-container{
    >.nav{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: $spacing-small;
        .category-button{
            @include font-tiny_text;
            color: $c-black;
            font-weight: $font_weight-semi-bold;
            line-height: 0.9;

            cursor: pointer;
            padding-left: $spacing-small;
            padding-right: $spacing-small;
            border-right: 2px solid $c-black;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            transition: color 0.5s;

            &:first-child{
                padding-left: 0;
            }
            &:last-child{
                padding-right: 0;
                border-right: 0;
            }
            &.active{
                color: $c-light_blue;
            }
        }
    }
    .filter-slider{



    }
}

.results-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1.5vw;

    .result{
        flex: 0 0 32%;
        display: block;

        opacity: 1;
        transition: opacity 0.5s;

        &.flip-up{

            opacity: 0;
        }
        &.hidden{
            display: none;
        }
        >.padding-wrapper{
            position: relative;
            margin-top: 1.5vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
        &:first-child >.padding-wrapper{
            margin-top: 0;
        }


        .image-container{
            position: absolute;
            top: 0;left: 0;right: 0;bottom: 0;
            .image-canvas{
                position: absolute;
                top: 0;left: 0;right: 0;bottom: 0;
                background-position: center;
                background-size: cover;
                height: auto;
            }
            &:before{
                content: ' ';
                display: block;

                position: absolute;
                top: 0;left: 0;right: 0;bottom: 0;
                background-color: #000;
                opacity: 0.4;
                transition: opacity 1s;
            }
        }
        &:hover .image-container:before{
            opacity: 0.6;
        }


        .content-container{
            padding: $spacing-small;
            color: $c-black;
            .padding-wrapper{
                position: relative;
                z-index: 2;
            }

            .meta{
                @include font-tiny_text;
                color: inherit;
                position: relative;
                margin-top: 30px;
                margin-bottom: 20px;
                &:before{
                    content: ' ';
                    width: 120px;
                    height: 3px;
                    background-color: $c-black;
                    display: block;
                    position: absolute;
                    top: -30px;

                }
            }
            .excerpt{
                font-weight: 300;
            }
        }

    }

    @media screen and (min-width: $result_flickover_width){
        .result:nth-child(1){
            flex: 0 0 100%;
            >.padding-wrapper{
                height: 500px;
            }
            .image-container{
                position: absolute;
                top: 0;left: 0;right: 0;bottom: 0;
                z-index: 1;
                &:before{
                    content: ' ';
                    display: block;
                    position: absolute;
                    top: 0;left: 0;right: 0;bottom: 0;
                    z-index: 1;

                    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.4+0,0.7+50,0.9+84 */
                    background: linear-gradient(to bottom, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0.7) 50%,rgba(0,0,0,0.9) 84%,rgba(0,0,0,0.9) 100%);
                }
            }
            .content-container{
                position: relative;
                z-index: 2;
                color: $c-white;
                .meta:before{
                    background-color: $c-white;
                }
            }
        }
        .result:nth-child(2),
        .result:nth-child(3),
        .result:nth-child(4),
        .result:nth-child(5){
            flex: 0 0 49%;
            >.padding-wrapper{
                //height: 40vh;
                //min-height: 300px;
            }

            .image-container{
                position: relative;
                .image-canvas{
                    position: static;
                    height: 250px;
                }
            }
            .content-container{
                background-color: $c-light_grey;
                position: static;
            }
        }
        .result:nth-child(6),
        .result:nth-child(7),
        .result:nth-child(8){
            .image-container{
                position: relative;
                .image-canvas{
                    position: static;
                    height: 165px;
                }
            }
            .content-container{
                background-color: $c-light_grey;
                position: static;
            }
        }
    }
}

@media screen and (max-width: $result_flickover_width){
    .results-container{
        .result{
            flex: 0 0 100%;
            .image-container{
                position: relative;
                .image-canvas{
                    position: static;
                    height: 165px;
                }
            }
            .content-container{
                background-color: $c-light_grey;
                position: static;
            }
        }
    }

}
