$target-page-width: 1200px;



$spacing-huge: 120px;
$spacing-large: 80px;
$spacing-normal: 40px;
$spacing-small: 20px;
$spacing-tiny: 10px;


//WIDTHS
.full-width{
    width: 100%;
    float: left;
}


.content-width-small{
    $width:800px;


    width: 100%;
    max-width: $width;
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacing-tiny;
    padding-right: $spacing-tiny;
    &.half{
        //width: 50%;
        max-width: ($width/2);
        margin-left: 0;
        margin-right: 0;

        &.left{
            margin-left: auto;
        }
        &.right{
            margin-right: auto;
        }
    }
}


.content-width{
    $width:1200px;


    width: 100%;
    max-width: $width;
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacing-tiny;
    padding-right: $spacing-tiny;
    &.half{
        //width: 50%;
        max-width: ($width/2);
        margin-left: 0;
        margin-right: 0;

        &.left{
            margin-left: auto;
        }
        &.right{
            margin-right: auto;
        }
    }
}

.content-width-large{
    $width:1600px;


    width: 100%;
    max-width: $width;
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacing-tiny;
    padding-right: $spacing-tiny;
    &.half{
        //width: 50%;
        max-width: ($width/2);
        margin-left: 0;
        margin-right: 0;

        &.left{
            margin-left: auto;
        }
        &.right{
            margin-right: auto;
        }
    }
}


.content-width-huge{
    $width:2000px;


    width: 100%;
    max-width: $width;
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacing-tiny;
    padding-right: $spacing-tiny;
    &.half{
        //width: 50%;
        max-width: ($width/2);
        margin-left: 0;
        margin-right: 0;

        &.left{
            margin-left: auto;
        }
        &.right{
            margin-right: auto;
        }
    }
}


//positioning

.center-child{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}




//Canvases

.image-canvas{
    width: 100%;
    height: 400px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}







//Split
.split{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.split > *{
    flex: 1 0 1px;
}

@mixin hero-background-image-overlay(){
    position: relative;
    &:before{
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $c-dark_blue;
        opacity: 0.2;
    }
}



.dropdown-menu-button{
    cursor: pointer !important;
}


.delink a{
    pointer-events: none;
}
