.full-width-video-cta{
    $bg-col: transparentize($c-dark_blue, 0.4);
    background-color: $bg-col;
    padding-top: $spacing-normal;
    margin-bottom: $spacing-normal;

    /*While hidden*/
    padding: 0;
    margin: 0;
    background-color: transparent;
    /* // Hide */
    >.top{
        text-align: center;
        padding: 40px 0px 20px;
        .top-text{
            @include font-thin_heading;
            color: $c_white;
            margin-bottom: 0;
            font-weight: 300;
            font-size: 20pt;
        }
        h3{
            @include font-thin_heading-big;
            font-size: 32pt;
            color: $c-dark_blue;
        }
        p{
            @include font-body-regular;
            color: $c-white;
        }
    }
    >.bottom{

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .background{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;

            >.top{
                flex: 1 0 0;
            }
            >.bottom{
                flex: 1 0 0;
                background-color: $c-white;
            }
        }
        .foreground{
            position: relative;
            z-index: 10;
            video{
                padding: 20px 0;
            }
        }

    }
}
