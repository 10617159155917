.bn-button{
    @include font-button_text;
    border: none;
    padding: $spacing-tiny;
    display: inline-block;
    color: $c_white;
    cursor: pointer;
    -webkit-appearance: none;
    border-radius: 0;
    &:hover{
        color: darken($c_white, .4);
    }
}



.bn-button.outline{
    border: 1px solid $c-white;
    color: $c-white;
    background-color: transparent;
    transition: background-color 0.5s, color 0.5s;
    &:hover{
        background-color: $c-white;
        color: $c-black;
    }
}

.bn-button.wide{
    min-width: 180px;
    text-align: center;
}
.bn-button.wide-left{
    min-width: 180px;
    text-align: left;
    padding-left: 30px;
}
.bn-button.wide-right{
    min-width: 180px;
    text-align: right;
    padding-right: 30px;
}



.bn-button.solid{
    @include font-button_text;
    padding: 8px 15px;
    display: inline-block;
}

.bn-button.solid.c-red{
    color: $c_white;
    background-color: $c-red;
    border: $c-red solid 2px;

    transition: background-color 0.5s, color 0.5s;

    &:hover{
        background-color: $c-white;
        color: $c-red;
    }
}
.bn-button.solid.transparent-hover{
    &:hover{
        background-color: transparent;
    }
}

.bn-button.solid.c-dark_blue{
    color: $c_white;
    background-color: $c-dark_blue;
    border: $c-dark_blue solid 2px;

    transition: background-color 0.5s, color 0.5s;

    &:hover{
        background-color: $c-white;
        color: $c-dark_blue;
    }
}
