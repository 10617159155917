
.sliced-cta{
    border-top: #ccc 1px solid;
    padding: $spacing-large 0;
    .content{
        padding-bottom: $spacing-small;
        h3{
            @include font-thin_heading;
        }
        p{
            @include font-body-regular;
        }
    }
    .bn-button{
        margin-top: $spacing-small;
    }
}

.slice-container{
    position: relative;
    height: 33.33vw;//Keeps it square, as the windows are always 1/3 width.
    width: 100%;
    .background{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background-position: center;
        background-size: cover;
    }
    .foreground{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;

        position: relative;
        z-index: 10;
        .window{
            flex: 0 0 33.33%;
            height: 100%;
            border-left: 4px $c_white solid;
            border-right: 4px $c-white solid;

            &:first-child{
                border-left: none;
            }
            &:last-child{
                border-right: none;
            }
        }
    }
}
