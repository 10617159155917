








.testimonials-body{
    padding: $spacing-normal 0;
    .title-container{
        h3{
            @include font-thin_heading;
            margin-bottom: $spacing-small;
            strong{
                font-weight: $font_weight-semi-bold;
            }
        }
    }
    .results-container{
        .result{
            flex: 0 0 32%;

            &:nth-child(1){
                //flex: 0 0 100%;
                >.padding-wrapper{
                    height: auto;//40vw;
                    margin-top: 1.5vw;
                }
                .image-container{
                    position: relative;//absolute;
                    //top: 0;left: 0;right: 0;bottom: 0;
                    //z-index: 1;
                    .image-canvas{
                        height: 250px;
                        position: static;
                    }
                    &:before{
                        content: ' ';
                        display: block;
                        position: absolute;
                        top: 0;left: 0;right: 0;bottom: 0;
                        background-color: #000;
                        opacity: 0.4;
                        transition: opacity 1s;
                    }
                }
                .content-container{
                    position: relative;
                    //z-index: 2;
                    color: $c-true_black;//$c-white;
                    background-color: $c-light_grey;
                    position: static;
                    .meta:before{
                        background-color: $c-true_black//$c-white;
                    }
                }
                &:hover .image-container:before{
                    opacity: 0.6;
                }
            }
        }
        .video-wrapper{
            flex: 0 0 100%;
            video{
                margin-top: 1.5vw;
                height: 55.55vw;
                width: 100%;
                background-color: #333;
                display: block;
            }
        }
    }
}
@media screen and (max-width: 800px){
    .testimonials-body{
        .results-container{
            .result{
                flex: 0 0 100%;
            }
        }
    }
}
