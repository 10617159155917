
$sticky-menu-height: 66px;
$dropdown-menu-button-height: 90px;

/* line 3, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu-section {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #fff;
}

/* line 11, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu-section > .content-width {
  position: relative;
}

/* line 15, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu-section > .content-width > .menu-button {
  background-color: #e60023;
  color: #ffffff;
  width: 110px;
  height: $dropdown-menu-button-height;
  padding-top: 22px;
  padding-bottom: 0px;
  position: absolute;
  right: -200px;
  top: 0;
  z-index: 200;
  cursor: pointer;
  transition: background-color 0.5s;
}

/* line 30, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu-section > .content-width > .menu-button:hover {
  background-color: #bb031f;
}

@media screen and (max-width: 1650px) {
  /* line 15, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
  #header-menu-section > .content-width > .menu-button {
    right: 10px;
    top: $sticky-menu-height;
  }
}

/* line 40, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu-section > .content-width > .menu-button .icon {
  width: 34px;
  height: 37px;
  margin: auto;
}

/* line 44, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu-section > .content-width > .menu-button .icon .icon-line {
  width: 100%;
  height: 2px;
  margin-bottom: 5px;
  background-color: #ffffff;
}

/* line 50, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu-section > .content-width > .menu-button .icon .icon-line:last-child {
  margin-bottom: 0;
}

/* line 55, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu-section > .content-width > .menu-button .text {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 9.6pt;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  font-size: 8.5pt;
}

/* line 66, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: $sticky-menu-height;
}



/* line 89, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu #menu-main-menu-left,
#header-menu #menu-main-menu-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* line 95, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu .menu-item {
  position: relative;
}

/* line 98, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu .menu-item > .sub-menu {
  padding-top: 23px;
  display: none;
  position: absolute;
  top: 24px;
  left: -10px;
  right: 0;
  width: 300px;
  font-size: 13pt;
}

/* line 109, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu .sub-menu > .menu-item {
  padding: 5px 10px;
  background-color: #EEF0F2;
  transition: background-color 0.5s;
}

/* line 115, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu .sub-menu > .menu-item:hover {
  background-color: #aaaaaa;
}

/* line 118, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu .sub-menu > li > a {
  font-size: 13pt;
}

/* line 121, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu .menu-item:hover > .sub-menu,
#header-menu .sub-menu:hover {
  display: block;
}

/* line 126, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu .left {
  flex: 1 0 1px;
}

/* line 129, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu .middle {
  height: $sticky-menu-height;
  flex: 0 0 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* line 137, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu .middle img {
  //margin: auto;
  height: 50px;
}

/* line 142, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#header-menu .right {
  flex: 1 0 1px;
}








/* line 76, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */


@mixin nav-item-underline(){
    content: '';
    display: block;
    position: absolute;
    bottom: -3px;
    width: 100%;
    left: 0%;
    height: 3px;
    background-color: #e60023;
}

#header-menu a {
    font-family: "Fira Sans", sans-serif;
    font-weight: 400;
    font-size: 12.8pt;
    color: #111F25;
    font-size: 11.6pt;
    position: relative;
    white-space: nowrap;
}
#header-menu ul.menu > li > a{
    &:after{
        @include nav-item-underline;
        width: 0%;
        transition: width 0.5s;
    }
    &:hover:after{
        width: 100%;
    }
}
#header-menu .current-menu-ancestor > a:after,
#header-menu .current-menu-item > a:after {
    @include nav-item-underline;
    width: 100% !important;
}





/* line 154, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#mobile-menu {
  position: absolute;
  top: 60px;
  left: 0;
  bottom: 0;
  transform: translateX(-100%);
  background-color: #ffffff;
  width: 200px;
  z-index: 9999;
  opacity: 0;
  transition: transform 0.5s, opacity 0.5s;
}

/* line 168, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#mobile-menu.active {
  opacity: 1;
  display: block;
  transform: translateX(0%);
}

/* line 175, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#mobile-menu ul li a {
  padding-left: 20px;
}

/* line 183, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#mobile-nav {
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: $sticky-menu-height;
}

/* line 190, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#mobile-nav .middle {
  height: $sticky-menu-height;
  flex: 0 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* line 198, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
#mobile-nav .middle img {
  margin: auto;
  height: 50px;
}

/*
#mobile-menu-button{
    //display: none;
    .icon{
        width: 30px;
        height: 30px;
        .icon-line{
            height: 2px;
            width: 100%;
            margin-bottom: 8px;
            background-color: $c-dark_blue;
        }
    }
}
*/
@media screen and (max-width: 800px) {
  /* line 225, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
  #mobile-nav {
    display: flex;
  }
  /* line 230, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
  #header-menu-section #header-menu {
    display: none;
  }
  /* line 233, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
  #header-menu-section #dropdown-menu-button.menu-button {
    background-color: transparent;
    color: #e60023;
    padding-top: 15px;
    padding-bottom: 15px;
    position: absolute;
    right: auto;
    left: 20px;
    top: 6px;
    height: auto;
    transition: color 0.5s;
  }
  /* line 245, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
  #header-menu-section #dropdown-menu-button.menu-button:hover {
    background-color: transparent;
    color: #bb031f;
  }
  /* line 249, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
  #header-menu-section #dropdown-menu-button.menu-button:hover .icon-line {
    background-color: #bb031f;
  }
  /* line 253, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
  #header-menu-section #dropdown-menu-button.menu-button .menu-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  /* line 259, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
  #header-menu-section #dropdown-menu-button.menu-button .icon {
    height: auto;
  }
  /* line 261, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
  #header-menu-section #dropdown-menu-button.menu-button .icon .icon-line {
    background-color: #e60023;
  }
  /* line 265, C:/Users/h.laird/Desktop/Development Sites/NOVO/Development/bn/sass/template-parts/_header-menu.scss */
  #header-menu-section #dropdown-menu-button.menu-button .text {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 9.6pt;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    color: inherit;
  }
}






#header-menu ul{
    list-style: none;
    padding-left: 0;
}
