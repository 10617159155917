.full-width-cta{
    height: 700px;
    position: relative;
    .content-width{
        height: 100%;
    }
    .background{
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background-size: cover;
        background-position: 30% 50%;
    }
    .foreground{
        height: 100%;
        position: relative;
        z-index: 10;
        .wrapper{
            width: 100%;
            >*{
                max-width: 50%;
                clear: both;
                @media screen and (max-width: 1200px){
                    max-width: 50%;
                }
                @media screen and (max-width: 600px){
                    max-width: 100%;
                }
            }
            h3{
                @include font-thin_heading-huge;
                font-size: 60pt;
                text-transform: uppercase;
            }
            p{
                @include font-thin_heading;
                font-size: 22pt;
            }
            .bn-button{

            }
        }
    }
}
