.meet-the-team-3{
    height: 33vw;
    @media screen and (max-width: 600px){
        height: 100vw;
    }
    .team-3-slider{
        position: relative;

        .team-member-container{
            overflow: hidden;
            height: 33vw;
            position: relative;
            @media screen and (max-width: 600px){
                height: 100vw;
            }
            .main{
                position: relative;
                z-index: 1;
                width: 100%;
                height: 100%;
                transform: translateX(0%);
                transition: transform 0.5s;

                will-change: transform;
                .background{
                    background-position: center;
                    background-size: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            .rollover{
                z-index: 2;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                transform: translateX(-100%);
                will-change: transform;
                transition: transform 0.5s;
                padding: $spacing-small;

                .padding-wrapper{
                    width: 100%;
                    height: 100%;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    & *{
                        width: 100%;
                    }
                }
                strong{
                    font-weight: 600;
                }

                .title{
                    @include font-thin_heading;
                    position: relative;
                    margin-bottom: 10px;

                    &:before{
                        content: ' ';
                        width: 120px;
                        height: 3px;
                        background-color: #111F25;
                        display: block;
                        position: absolute;
                        bottom: -5px;
                    }
                    strong{
                        font-weight: 300;
                    }
                }
                .role{

                }
                .contact-details{

                }
                .description{

                }
            }
            &:hover{
                .main{
                    transform: translateX(100%);
                }
                .rollover{
                    transform: translateX(0%);
                }
            }
            .swipe{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 10;
                transform: translatex(100%);
                transition: transform .5s;


                &.swipe-bg{
                    background-color: #fff;
                    transition-delay: 0.1s;
                }
                &.swipe-fg{
                    background-color: $c-dark_blue;
                    transition-delay: 0s;
                }
            }
            &.slides-in{
                .swipe{
                    transform: translatex(0%);
                    &.swipe-bg{
                        transition-delay: 0s;
                    }
                    &.swipe-fg{
                        transition-delay: 0.1s;
                    }
                }
            }
        }//team member container
    }

}
