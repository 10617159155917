.mini-content-section{
    text-align: center;
    padding-top: $spacing-large;
    padding-bottom: $spacing-large;
    background-color: $c-light_grey;

    h3{
        @include font-thin_heading;
    }
    p{
        font-weight: $font_weight-semi-bold;
        color: $c-light_blue;
    }
}

.content-section{
    text-align: center;
    padding-top: $spacing-normal;
    padding-bottom: $spacing-normal;
    background-color: $c-light_grey;
    h3{
        @include font-thin_heading;
        @media screen and (max-width: 1000px){
            font-size: 16pt;
        }
    }
}
