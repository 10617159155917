.contact-bar{
    padding: $spacing-normal 0;
    background-color: transparentize($c-dark_blue, 0.6);

    .content{
        text-align: center;
        color: $c-white;
        margin-bottom: $spacing-small;
        font-weight: $font_weight-light;
    }
    h3{
        @include font-thin_heading;
    }
}

.contact-form{
    .ajax-loader{
        display: none !important;
    }
    .flex-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;

        &:last-child{
            margin-bottom: 0;
        }
        .wpcf7-form-control-wrap{
            flex: 0 0 100%;
            input,
            textarea,
            select{
                @include font-default;
                font-size: 12pt;
                font-weight: $font_weight-light;
                width: 100%;
                padding: 15px 25px;
                margin: 0;
                border: $c-white 1px solid;
                border-radius: 0;
                color: $c-white;
                background: transparentize($c-white, 0.85);
                height: 55px;
                -webkit-appearance: none;

                &::placeholder{
                    color: $c-white;
                    opacity: 1;
                }
            }
            select::-ms-expand {
                display: none;
            }
            textarea{
                height: 170px;
                resize: vertical;
            }
            select option{
                color: $c-black;
            }
        }
        #submit{
            outline: none;
        }
    }

    .flex-row.three{
        .wpcf7-form-control-wrap{
            flex: 0 0 32%;

            input{
                width: 100%;
            }
        }
        @media screen and (max-width: 800px){
            flex-wrap: wrap;
            margin-bottom: 0;
            .wpcf7-form-control-wrap{
                flex: 0 0 100%;
                margin-bottom: $spacing-small;
            }
        }
    }
    .flex-row.two{
        .wpcf7-form-control-wrap{
            flex: 0 0 49%;

            input{
                width: 100%;
            }
        }
        @media screen and (max-width: 800px){
            flex-wrap: wrap;
            margin-bottom: 0;
            .wpcf7-form-control-wrap{
                flex: 0 0 100%;
                margin-bottom: $spacing-small;
            }
        }
    }
    .flex-row.center{
        justify-content: center;
    }

}
