.contact-page#main{
    .main-content{
        $dark-bg-col: mix($c-dark_blue, $c-white, 60%);
        position: relative;
        color: $c-white;
        .background{
            position: absolute;
            top: 0;left: 0;right: 0;bottom: 0;

            display: flex;
            justify-content: center;
            flex-direction: row;

            z-index: 0;
            > .two{
                flex: 0 0 60%;
                background-color: $dark-bg-col;
            }
            > .one{
                flex: 0 0 40%;
                background-color: $c-white;
            }
        }
        .foreground{
            display: flex;
            justify-content: center;
            flex-direction: row;
            position: relative;
            z-index: 1;
            > .two{
                flex: 0 0 60%;
                background-color: $dark-bg-col;
                .padding-wrapper{
                    padding: $spacing-huge $spacing-normal;
                    padding-right: $spacing-large;
                }
            }
            > .one{
                color: $c-black;
                flex: 0 0 40%;
                background-color: $c-white;
                .padding-wrapper{
                    padding: $spacing-huge $spacing-normal;
                }
            }
            @media screen and (max-width: 1100px){
                flex-wrap: wrap;
                padding: 0;
                > .two{
                    flex: 0 0 100%;
                }
                > .one{
                    flex: 0 0 100%;
                }
            }
        }
        .contact-form .flex-row.one.center{
            justify-content: flex-start;
        }

        h3{
            @include font-thin_heading;
            margin-bottom: .5em;
        }

        p{
            margin-bottom: 1em;
        }

        .small{
            @include font-body-small;
        }
        .bold{
            font-weight: $font_weight-semi-bold;
        }
        a{
            color: $c-light_blue;
            transition: color 0.5s;
            font-weight: 600;
            &:hover{
                color: $c-dark_blue;
            }
        }
    }
}

.social-icons-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .social-icon{
        img{
            padding-left: $spacing-tiny;
            padding-right: $spacing-tiny;

        }
        &:first-child img{
            padding-left: 0;
        }
        &:last-child img{
            padding-right: 0;
        }
    }
}
