@import url('https://fonts.googleapis.com/css?family=Fira+Sans:200,300,300i,400,600,700|Montserrat:300,400,600,700');


$font-multiplier: 0.8; //Global font size correction.

$font-header: 'Fira Sans', sans-serif;
$font-menu: 'Montserrat', sans-serif;
$font-body: 'Fira Sans', sans-serif;



//FONT WEIGHTS
$font_weight-extra_light: 200;
$font_weight-light: 300;
$font_weight-normal: 400;
$font_weight-semi-bold: 600;
$font_weight-bold: 700;
$font_weight-extra_bold: 800;


/*******************************************************************************
Text center
*******************************************************************************/
.text-left{
    text-align: left;
}

.text-center{
    text-align: center;
}

.text-right{
    text-align: right;
}


h1 + p,
h2 + p,
h3 + p,
h4 + p,
h5 + p,
h6 + p,
h1 + strong,
h2 + strong,
h3 + strong,
h4 + strong,
h5 + strong,
h6 + strong,
h1 + ul,
h2 + ul,
h3 + ul,
h4 + ul,
h5 + ul,
h6 + ul,
h1 + ol,
h2 + ol,
h3 + ol,
h4 + ol,
h5 + ol,
h6 + ol{
    margin-top: 1em;
}
p,
ul,
ol{
    margin-bottom: 1em;
    &:last-child{
        margin-bottom: 0;
    }
}



/*******************************************************************************
Lists
*******************************************************************************/
ul{
    padding-left: 1em;
    list-style: disc;
}


/*******************************************************************************
Begin Fonts
*******************************************************************************/
@mixin font-default {
    font-family: $font-body;
    font-weight: 300;
    font-size: (16pt * $font-multiplier);
    line-height: 1.65;
}
@mixin font-button_default{
    font-family: $font-body;
    font-weight: $font_weight-bold;
    font-size: (13.5pt * $font-multiplier);
}
/*
@mixin font-testimonial_box{
    font-family: $font-body;
    font-weight: $font_weight-light;
    font-size: $font_size-normal;
}
@mixin font-heading-default{
    font-family: $font-body;
    font-weight: $font_weight-normal;
    font-size: $font_size-normal;
}
@mixin font-heading-blog{
    font-family: $font-body;
    font-weight: $font_weight-normal;
    font-size: $font_size-normal;
}
@mixin font-heading_large{
    font-family: $font-body;
    font-weight: $font_weight-normal;
    font-size: $font_size-normal;
}
@mixin font-heading_product {
    font-family: $font-body;
    font-weight: $font_weight-normal;
    font-size: $font_size-normal;
}
@mixin font-heading_huge{
    font-family: $font-body;
    font-weight: $font_weight-normal;
    font-size: $font_size-normal;
}
*/


@mixin font-tiny_text{
    font-family: $font-menu;
    font-weight: $font_weight-semi-bold;
    font-size: (12pt * $font-multiplier);
    text-transform: uppercase;
    color: $c-white;
}
@mixin font-button_text{
    font-family: $font-body;
    font-weight: $font_weight-bold;
    font-size: (13.5pt * $font-multiplier);
    line-height: 1.85;
}

@mixin font-menu_items_text{
    font-family: $font-body;
    font-weight: $font_weight-normal;
    font-size: (16pt * $font-multiplier);
    color: $c-black;
    transition: color 0.5s;

    &:hover{
        color: $c-light_blue;
    }
}


@mixin font-body-small{
    font-family: $font-body;
    font-weight: $font_weight-normal;
    font-size: (13.5pt * $font-multiplier);
    line-height: (20 / 13.5);
}
@mixin font-body-regular{
    font-family: $font-body;
    font-weight: $font_weight-normal;
    font-size: (16pt * $font-multiplier);
    line-height: (25 / 16);
}

@mixin font-thumbnail_title{
    font-family: $font-menu;
    font-weight: $font_weight-semi-bold;
    font-size: (12pt * $font-multiplier);
    text-transform: uppercase;
    color: $c_white;
}
@mixin font-slider_heading{
    font-family: $font-body;
    font-weight: $font_weight-semi-bold;
    font-size: (48pt * $font-multiplier);
}


@mixin font-thin_heading{
    font-family: $font-body;
    font-weight: $font_weight-extra_light;
    font-size: ((20pt * (1 / 0.8)) * $font-multiplier);
    line-height: (40 / 30);
    @media screen and (max-width: 800px){
        font-size: ((30pt * .7) * $font-multiplier);
    }
}
@mixin font-thin_heading-big{
    @include font-thin_heading;
    font-size: (48pt * $font-multiplier);
    @media screen and (max-width: 800px){
        font-size: ((48pt * .7) * $font-multiplier);
    }
}
@mixin font-thin_heading-huge{
    @include font-thin_heading;
    font-size: (75pt * $font-multiplier);
    @media screen and (max-width: 800px){
        font-size: ((75pt * .7) * $font-multiplier);
    }
}





@mixin user_input_area{
    h1,h2,h3,h4,h5,h6{
        @include font-thin_heading;
    }
    h1{
        font-size: 40pt;
    }
    h2{
        font-size: 37pt;
    }
    h3{
        font-size: 32pt;
    }
    h4{
        font-size: 22pt;
    }
    h5{
        font-size: 18pt;
    }
    h6{
        font-size: 16pt;
    }
    & strong{
        font-weight: 500;
    }
    & em{
        font-style: italic;
    }
}
