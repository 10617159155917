.call-bar{
    @include font-thin_heading;
    padding: $spacing-normal 0;
    background-color: $c-dark_blue;
    color: $c_white;

    strong{
        font-weight: $font_weight-semi-bold;
    }
}
