.testimonial-slider-bar{
    padding: $spacing-normal 0;
    position: relative;
    background-color: $c-light_grey;
    overflow: hidden;


    ul{
        list-style: none;
        padding-left: 0;
    }



    >.content-width.background{
        position:absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        z-index: 0;
        img{
            position: relative;
            top: 15%;
            left: -300px;
            right: 0;
            bottom: 0;
            max-height: 70%;
        }
    }



    .content-width{
        position: relative;
        z-index: 10;
    }

    .slider-wrapper{
        width: 100%;
        .testimonial-slider{

        }
        .progress{

        }

        .slide{
            text-align: center;
            padding: $spacing-normal 0;


            .quote{
                font-family: $font-body;
                font-weight: $font_weight-light;
                font-size: 20pt;
                line-height: (40 / 20);
                font-style: italic;
                margin-bottom: $spacing-small
            }
            .meta{
                @include font-body-regular;

                .author{
                    font-weight: $font_weight-bold;
                }
                .spacer{

                }
                .company{

                }
            }
        }
    }
    .slick-dots{
        display: flex;
        flex-direction: row;
        justify-content: center;
        li{
            margin: $spacing-tiny/2;
            outline:none;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            border: $c-black 1px solid;
            cursor: pointer;

            transition: background-color 0.5s;


            button{
                display: none;
            }
            &.slick-active{
                background-color: $c-black;
            }
        }
    }
}
