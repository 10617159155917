
//BASICS
$c-true_black: #111;
$c-black: #111F25; //?
$c-dark_blue: #19272e;
$c-white: #ffffff;
$c-red: #e60023;
$c-light_blue: #7ba0af;
$c-light_grey: #EEF0F2; //?
$c-grey: #aaaaaa;
