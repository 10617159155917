



.blog-showcase{
    width: 100%;
    height: 700px;
    @media screen and (max-width: 900px){
        height: auto;
    }
}

.blog-thumbnail{
    .text-content{

    }
}

.blog-showcase-square-thumbnail{
    position: relative;

    .background{
        position: absolute;
        top: 0;left: 0;right: 0;bottom: 0;
        z-index: 1;

        background-size: cover;
        background-position: center;

        &:before{
            content: ' ';
            display: block;
            position: absolute;
            top: 0;left: 0;right: 0;bottom: 0;
            background-color: #000;
            opacity: 0.4;
            transition: opacity 1s;
        }
    }
    &:hover .background:before{
        opacity: 0.6;
    }
    .foreground{
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 2;

        .wrapper{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: $spacing-small;
            color: $c-white;
        }
        .meta{
            @include font-tiny_text;
            position: relative;
            margin-top: 30px;
            margin-bottom: 20px;
            &:before{
                content: ' ';
                width: 120px;
                height: 3px;
                background-color: $c-white;
                display: block;
                position: absolute;
                top: -30px;

            }
        }
        .excerpt{
            font-weight: 300;
        }
    }


    display: none;

    &:nth-child(1){
        width: 50%;
        float: left;
        height: 100%;
        display: block;
    }
    &:nth-child(2){
        float: left;
        width: 50%;
        height: 50%;
        display: block;
    }
    &:nth-child(3){
        float: left;
        width: 25%;
        height: 50%;
        display: block;
    }
    &:nth-child(4){
        float: left;
        width: 25%;
        height: 50%;
        display: block;
    }
    @media screen and (max-width: 900px){
        $masterHeight: 600px;
        &:nth-child(1){
            width: 100%;
            height: ($masterHeight / 2);
        }
        &:nth-child(2){
            width: 100%;
            height: ($masterHeight / 2);
        }
        &:nth-child(3){
            width: 50%;
            height: ($masterHeight / 2);
        }
        &:nth-child(4){
            width: 50%;
            height: ($masterHeight / 2);
        }
    }

    @media screen and (max-width: 500px){
        $masterHeight: 900px;
        &:nth-child(1){
            width: 100%;
            height: ($masterHeight / 4);
        }
        &:nth-child(2){
            width: 100%;
            height: ($masterHeight / 4);
        }
        &:nth-child(3){
            width: 100%;
            height: ($masterHeight / 4);
        }
        &:nth-child(4){
            width: 100%;
            height: ($masterHeight / 4);
        }
    }
}
.blog-body-outer{
    background-color: $c-light_grey;
}
.blog-body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1600px;
    margin: auto;
    float: none;
    background-color: $c-white;
    .main{
        //max-width: 1200px;
        flex: 0 0 70%;
        padding-left: 3vw;
        width: 100%;
        overflow: hidden;
        padding-top: $spacing-normal;
        padding-bottom: $spacing-normal;
        .title-container{
            h3{
                @include font-thin_heading;
                margin-bottom: $spacing-small;
                strong{
                    font-weight: $font_weight-semi-bold;
                }
            }
        }
    }
    .sidebar{
        flex: 0 0 25%;
    }
}




.blog-body .main{

}

.blog-post-thumbnail{
    display: block;

    &.hidden{
        display: none;
    }
    >.padding-wrapper{
        position: relative;
        margin-top: 1.5vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    &:first-child >.padding-wrapper{
        margin-top: 0;
    }


    .image-container{
        position: relative;
        height: 170px;
        .image-canvas{
            position: absolute;
            top: 0;left: 0;right: 0;bottom: 0;
            background-position: center;
            background-size: cover;
            height: auto;
        }
    }


    .content-container{
        padding: $spacing-small 0;
        color: $c-black;
        .padding-wrapper{
            position: relative;
            z-index: 2;
        }

        .meta{
            @include font-tiny_text;
            color: inherit;
            position: relative;
            margin-top: 30px;
            margin-bottom: 20px;
            &:before{
                content: ' ';
                width: 120px;
                height: 3px;
                background-color: $c-black;
                display: block;
                position: absolute;
                top: -30px;

            }
        }
        .excerpt{
            font-weight: 300;
        }
    }
}


.sidebar{

    background-color: $c-light_grey;


    >section{
        padding: $spacing-normal;
        @media screen and (max-width:900px){
            padding-left: 0;
            padding-right: 0;
        }
    }
    .title{
        @include font-thin_heading;
        margin-bottom: $spacing-small;
        strong{
            font-weight: $font_weight-semi-bold
        }
    }

    .most-recent{
        .content{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;

            .blog-post-thumbnail{
                flex: 0 0 100%;
                >.padding-wrapper{
                    margin-top: 0;
                }
            }
        }
    }
}

.site-main.single{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1600px;
    margin: auto;
    float: none;
    background-color: #ffffff;
    >.single-content{
        flex: 0 1 1200px;
    }
    >.sidebar{
        flex: 0 0 400px;
        position: relative;
        z-index: 1;
        padding-top: $dropdown-menu-button-height + 20px;
    }
    @media screen and (max-width: 900px){
        flex-wrap: wrap;
        >.single-content{
            flex: 0 0 100%;
        }
        >.sidebar{
            flex: 0 0 100%;
        }
    }

}

.single-title-bar{
    //Setup for masking.
    display: flex;
    flex-direction: row;
    justify-content: flex-end;





    &.title-bar-image-bg,
    &.title-bar-content{
        height: 600px;
        width: 75%;
    }
    &.title-bar-image-bg{
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 0;
        z-index: 0;
        &:before{
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.4+0,0.7+50,0.9+84 */
            background: linear-gradient(to bottom, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0.7) 50%,rgba(0,0,0,0.9) 84%,rgba(0,0,0,0.9) 100%);
            content: ' ';
            display: block;
        }
    }
    &.title-bar-content{
        position: relative;
        top: -$sticky-menu-height;
        .wrapper{
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 0 $spacing-small;
        }
        .meta{
            @include font-tiny_text;
            color: inherit;
            position: relative;
            margin-top: 30px;
            margin-bottom: 20px;
            color: $c-white;
            &:before{
                content: ' ';
                width: 120px;
                height: 3px;
                background-color: $c-white;
                display: block;
                position: absolute;
                top: -30px;

            }
        }
        .title{
            @include font-thin_heading;
            color: $c-white;
            padding-bottom: $spacing-normal;
        }
    }
}

.single-content{
    .single-content-area{
        padding: $spacing-small;
    }
}



@media screen and (max-width: 900px){
    .blog-body{
        flex-wrap: wrap;
        .main{
            flex: 0 0 100%;
            padding-left: $spacing-small;
            padding-right: $spacing-small;
        }
        .sidebar{
            flex: 0 0 100%;
            padding-left: $spacing-small;
            padding-right: $spacing-small;
            .most-recent .content{
                > .blog-post-thumbnail{
                    flex: 0 0 49%;
                }
            }
        }
    }
}

@media screen and (max-width: 600px){
    .blog-body{
        .main{
        }
        .sidebar{
            .most-recent .content{
                > .blog-post-thumbnail{
                    flex: 0 0 100%;
                }
            }
        }
    }
}

.post-template-default.single{
    background-color: $c-light_grey;
}
