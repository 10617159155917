.floating-logo-bar{
    display: none;
    position: relative;
    img{
        position: absolute;
        top: -2.5vw;
        width: 5vw;
        height: 5vw;
        max-width: none;
        max-height: none;
    }
}
@media screen and (max-width: 650px){
    .floating-logo-bar{
        display:none;
    }
}

.floating-logo-bar.large{
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #19272e;
    .line{
        flex: 1 0 0px;
        border-top: transparentize($c_white, 0.8) solid 1px;
    }
    .wrapper{
        background-color: #19272e;
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            width: 100px;
            height: 100px;
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            margin:auto;
        }
    }
}
