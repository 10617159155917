.team-slider{
    .slick-track{
        background-color: $c-light_grey;
    }
    .slick-arrow{
        background-color: transparent;
        border: none;
        padding: 0;
        position: absolute;
        z-index: 1;
        top: 38%;
        color: transparent;
        font-size: 0px;
        cursor: pointer;
        opacity: 0.7;

        transition: opacity 0.5s;
        &:hover{
            opacity: 1;
        }
    }
    $arrow-size: 25px;
    .slick-prev{
        left: 15px;
        width: 0;
        height: 0;
        border-top: $arrow-size solid transparent;
        border-bottom: $arrow-size solid transparent;
        border-right: $arrow-size solid $c-white;
    }
    .slick-next{
        right: 15px;
        width: 0;
        height: 0;
        border-top: $arrow-size solid transparent;
        border-bottom: $arrow-size solid transparent;
        border-left: $arrow-size solid $c-white;
    }
}




.team-member-container{
    .top{
        position: relative;
        overflow: hidden;
        height: 500px;
        .background{
            position: absolute;
            top: 0;left: 0;right: 0;bottom: 0;
            z-index: 1;
            background-position: center;
            background-size: cover;
        }
        .foreground{
            position: absolute;
            top: 0;right: 0;left: 0;bottom: 0;
            background-color: transparentize($c-dark_blue, .2);
            opacity: 0;
            z-index: 2;

            transition: transform .7s, opacity .7s;
            transform: translateY(100%);
            .content{
                position: absolute;
                bottom: 0;
                @include font-default;
                font-weight: $font_weight-light;
                font-size: 13pt;
                padding: $spacing-tiny;
                color: $c-white;
            }
        }
    }
    &:hover .foreground{
        transform: translateY(0%);
        opacity: 1;
    }
    .bottom{
        padding: 25px;
        background-color: $c-light_grey;
        .title{
            position: relative;
            margin-bottom: 10px;

            &:before{
                content: ' ';
                width: 120px;
                height: 3px;
                background-color: #111F25;
                display: block;
                position: absolute;
                bottom: -5px;
            }
        }
        .role{
            font-size: 13pt;
            display: inline-block;
            margin-bottom: 5px;
        }
        .contact-details{
            a,p{
                font-size: 13pt;
                display: inline-block;
            }
            strong{
                margin-right: .5em;
            }
        }


        strong{
            font-weight: $font_weight-semi-bold;
            display:inline-block;
            font-size: 13pt;
        }
    }
}
