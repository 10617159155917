.latest-news{
    $thumbnail-height: 220px;
    @media screen and (max-width: 800px){
        .post-thumbnail-container{
            flex-wrap: wrap;
            .post-thumbnail{
                flex: 0 0 100%;
                height: auto;
                min-height: $thumbnail-height;
                .background,
                .foreground{
                    min-height: $thumbnail-height;
                    margin-bottom: 10px;
                }
            }
        }
    }
    padding: $spacing-large 0;

    h3{
        @include font-thin_heading;
        padding-bottom: $spacing-small;
    }

    .read-more .bn-button{
        margin-top: $spacing-normal;
    }
    .post-thumbnail-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }
    .post-thumbnail{
        flex: 0 0 32%;
        height: $thumbnail-height;
        .padding-wrapper{
            position: relative;
            height: 100%;
            width: 100%;
        }
        .background{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            background-size: cover;
            background-position: center;
            &:after{
                content: ' ';
                display: block;
                position: absolute;
                top: 0;left: 0;right: 0;bottom: 0;
                background-color: transparentize($c-black, 0.6);
                transition: background-color 0.5s;
            }
        }
        .foreground{
            position: relative;
            z-index: 10;
            height: 100%;
            width: 100%;

            .wrapper{
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                padding: $spacing-small;
            }
            .top{
                @include font-thumbnail_title;
                font-size: 10pt;
                padding-bottom: 5px;
            }
            .bottom{
                @include font-body-regular;
                color: $c_white;
                font-size: 12pt;
            }
        }
        &:hover .background:after{
            background-color: transparentize($c-black, 0.3);
        }
    }
}
