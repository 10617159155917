#mega-menu{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(-100%);
    opacity: 0;
    z-index: 999;

    display: flex;
    flex-direction: row;
    justify-content: center;

    transition: transform 0.5s, opacity 0.5s;

    ul{
        list-style: none;
        padding-left: 0;
    }
    &.active{
        transform: translateX(0%);
        opacity: 1;
    }
    .exit-button{
        position: absolute;
        right: 0;
        top: 0;

        background-color: $c-red;
        padding: 30px;
        z-index: 10;
        transition: background-color 0.5s;

        &:hover{
            background-color: darken($c-red, 5);
            .icon{
                transform: scale(1);
            }
        }
        cursor: pointer;
        .icon{
            $line-width: 2px;

            width: 30px;
            height: $line-width;
            transition: transform 0.5s;
            transform: scale(0.9);
            position: relative;
            top: ($line-width * -1);

            .line{
                background-color: $c-white;
                width: 100%;
                height: $line-width;
                transform: rotate(45deg);


                &:first-child{
                    position: relative;
                    top: $line-width;
                    transform: rotate(-45deg);
                }
            }
        }

    }
    .left{
        flex: 0 0 25%;
        text-align: center;
        height: 100%;
        .menu-mega-menu-left-container{
            height: 100%;
        }
        .menu{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 100%;
            li{
                flex: 1 0 1px;
                //max-height: 150px;

                $border: 1px solid $c-black;
                background-color: #1c1c1c;
                border: $border;
                border-bottom: none;

                display: flex;
                justify-content: center;
                align-items: center;

                color: $c-white;
                transition: background-color 0.5s;
                &:hover{
                    background-color: #111;
                }
                &:last-child{
                    border-bottom: $border;
                }
                a{
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include font-tiny_text;
                    font-size: 16pt;
                }
            }
        }
    }
    .products{
        flex: 0 0 75%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        >.product{
            flex: 0 0 50%;
            //height: 33%; //Might need this as a fix on ie or something.
            height: auto;
            position: relative;

            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            color: $c-white;
            cursor: pointer;

            background-position: center;
            background-size: cover;

            &:before{
                z-index: 1;
                display: block;
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                background-color: $c-true_black;
                opacity: 0.8;

                transition: opacity 0.5s;
            }
            &:hover:before{
                opacity: 0.2;
            }

            p{
                @include font-tiny_text;
                font-size: 16pt;
                position: relative;

                z-index: 2;
            }
        }
    }
}

@media screen and (max-width: 800px){
    #mega-menu{
        .left{
            .menu{
                li{
                    a{
                        font-size: 13pt;
                    }
                }
            }
        }
        .products{
            >.product{
                flex: 0 0 50%;

                p{
                    font-size: 13pt;
                }
            }
        }
    }
}

@media screen and (max-width: 800px){
    #mega-menu{
        .left{
            flex: 0 0 30%;
            .menu{
                li{
                    a{
                        font-size: 12pt;
                    }
                }
            }
        }
        .products{
            flex: 0 0 70%;
            >.product{
                flex: 0 0 50%;

                p{
                    font-size: 12pt;
                }
            }
        }
    }
}
@media screen and (max-width: 600px){
    #mega-menu{
        .exit-button{
            padding: 25px 12px;
            cursor: pointer;
            .icon{
                transform: scale(0.85);
            }
        }
        .left{
            flex: 0 0 35%;
            .menu{
                li{
                    a{
                        font-size: 11pt;
                    }
                }
            }
        }
        .products{
            flex: 0 0 65%;
            >.product{
                flex: 0 0 100%;

                p{
                    font-size: 11pt;
                    text-align: left;
                    width: 100%;
                    padding-left: 20px;
                }
            }
        }
    }
}
