.landing-intro{
    margin-top: $spacing-normal;
    margin-bottom: $spacing-normal;
    .split{

        .text{
            //Font assignment
            h3{
                @include font-thin_heading;
                margin-bottom: $spacing-small;
                padding-right: 10%;
                font-size: 16pt;

            }

            .top{
                max-width: 100%;
            }
            .bottom{
                .logo{

                }
                .text{

                }
                .cta-text{

                }
            }
        }
        .image-wrapper{
            img{

                //height: 100%;
                //width: auto;
                max-height: 400px;
            }
        }
        .center-child{
            height: 100%;
        }
        .padding-wrapper.text-container{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        > div{
            flex: 1 1 50%;
        }
    }
}

@media screen and (max-width: 800px){
    .landing-intro{
        .split{
            flex-wrap: wrap;
            >div{
                flex: 0 0 100%;
            }
            .text{
                text-align: center;
                padding-left: 20px;
                padding-right: 20px;
                h3{
                    padding: 0;
                }
            }
        }
    }
}
