.product-thumbnail-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
@media screen and (max-width: 900px){
    .product-bar-4{
        .product-thumbnail-container{
            flex-wrap: wrap;
            &>*{
                flex: 0 0 50%;
            }
        }
    }
}
@media screen and (max-width: 600px){
    .product-bar-4{
        .product-thumbnail-container{
            flex-wrap: wrap;
            &>*{
                flex: 0 0 100%;
            }
        }
    }
}
.product-thumbnail.full-image{
    width: 100%;
    height: 500px;
    @media screen and (max-width: 1000px){
        height: 400px;
    }

    >.padding-wrapper{
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    &:first-child >.padding-wrapper{
        >.padding-wrapper{
            margin-left: 0;
        }
    }
    &:last-child >.padding-wrapper{
        >.padding-wrapper{
            margin-right: 0;
        }
    }
    .background,.foreground,.overlay{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .background{
        overflow: hidden;

        .image-canvas{
            height: 100%;
            width: auto;
            max-height: 100%;
            max-width: none;
            &:after{
                content: ' ';
                display: block;

                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                background-color: transparentize($c_black, 0.6);
            }
        }
    }

    .foreground{
        padding: $spacing-normal;

        .title{
            @include font-tiny_text;
        }
    }

    .overlay{
        top: auto;


        height: 600px / 5;

        background-color: $c-black;
        padding: 20px 40px 40px 40px;

        opacity: 0;
        transform: translateY(100%);

        transition: transform 0.5s, opacity 0.5s;
        .title-bar{
            @include font-default;
            color: $c_white;
            padding-bottom: $spacing-small
        }
        .button-bar{

        }
    }
    &:hover .overlay{
        opacity: 1;
        transform: translateY(0%);
    }
}
