.search-results{
    .results-container{
        .result{
            flex: 0 0 32%;

            &:nth-child(1){
                //flex: 0 0 100%;
                >.padding-wrapper{
                    height: auto;//40vw;
                    margin-top: 1.5vw;
                }
                .image-container{
                    position: relative;//absolute;
                    //top: 0;left: 0;right: 0;bottom: 0;
                    //z-index: 1;
                    .image-canvas{
                        height: 25vw;
                        position: static;
                        min-height: 300px;
                    }
                    &:before{
                        content: ' ';
                        display: block;
                        position: absolute;
                        top: 0;left: 0;right: 0;bottom: 0;
                        background-color: #000;
                        opacity: 0.4;
                        transition: opacity 1s;
                    }
                }
                .content-container{
                    position: relative;
                    //z-index: 2;
                    color: $c-true_black;//$c-white;
                    background-color: $c-white;
                    position: static;
                    .meta:before{
                        background-color: $c-true_black//$c-white;
                    }
                }
                &:hover .image-container:before{
                    opacity: 0.6;
                }
            }

            .content-container{
                background-color: $c-white !important;
            }

            &:last-child{
                //left align last thumbnail
                margin-right: auto;
                margin-left: 2%;
            }
            &:nth-child(3n){
                //left align last thumbnail, no margin if Last one.
                margin-left: 0;
                margin-right: 0;
            }
            &:nth-child(3n+1){
                //left align last thumbnail, no margin if first one.
                margin-left: 0;
            }
        }
    }

}
body.search-results,
body.search-no-results{
    background-color: $c-light_grey;
    .title-bar{

    }
    .search-intro{
        h3{
            @include font-thin_heading;
            margin-top: $spacing-normal;
            margin-bottom: $spacing-small;
        }
    }
    .search-results{

    }
}
body.search-no-results{
    h3{
        @include font-thin_heading;
        font-size: 12pt;
        margin-top: $spacing-normal;
        margin-bottom: $spacing-small;
    }
}
.search{
    float: left;
    margin-bottom: $spacing-normal;
    .search-form{
        $border-radius: 3px;
        float: left;
        label{
            position: relative;
            display: block;
            float: left;
        }
        .search-submit,
        .search-field{
            height: 35px;
            margin: 0;
            padding: 0;
            display: block;
            float: left;
            outline: none;
            border: none;
            background-color: $c-white;
        }
        .search-submit,
        .search-submit-pseudo{
            width: 35px;
            height: 35px;
        }
        .search-submit{
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            z-index: 2;
            background-color: transparent;
            cursor: pointer;
        }
        .search-submit-pseudo{
            display: flex;
            justify-content: center;
            align-items: center;
            float: left;
            background-color: $c-white;
            color: transparentize($c-black, 0.2);
            font-size: 15px;
            border-top-left-radius: 3px;
            border-top-left-radius: 3px;
        }
        .search-field{
            @include font-tiny_text;
            font-size: 15px;
            color: transparentize($c-black, 0.2);
            padding: 10px;
            width: 80%;
            width: calc(100% - 35px);

            //Fix safari being dodgy.
            border-radius: 0px;
            -webkit-appearance: none;
        }
    }

}
