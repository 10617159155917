#site-footer{
    font-weight: $font_weight-light;
    color: $c_white;
    background-color: $c-dark_blue;
    > *{
        float: left;
        width: 100%;
    }
    .row{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .col{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    @media screen and (max-width: 700px){
        .row{
            flex-wrap: wrap;
        }
        .col{
            flex: 0 0 100%;
            align-items: center;
        }
    }

    ul{
        list-style: none;
        padding-left: 0;
    }

    a{
        opacity: 1;
        transition: opacity 0.5s;
        &:hover{
            opacity: 0.5;
        }
    }
}

#footer-top-container{


    .contact-details{
        .padding-wrapper .wrapper{
            padding: 0 $spacing-normal;
            width: 320px;
            text-align: center;
        }
        img{
            margin: auto;
            height: 50px;
            width: 50px;
        }
        a{
            opacity: 1;
            transition: opacity 0.5s;
            &:hover{
                opacity: 0.5;
            }
        }
    }
    .products-list{
        //footer menu
        ul{
            float:left;
            padding-top: $spacing-small;
            padding-bottom: $spacing-small;
            li{
                float: left;
                text-align: center;
                a{
                    @include font-tiny_text;
                    color: $c-grey;
                    padding: $spacing-tiny;
                    transition: color 0.5s;
                    &:hover{
                        color: $c-light_blue;
                    }
                }
            }
        }
    }
    .social{
        justify-content: center;
        .social-icon{
            img{
                margin-top: $spacing-small;
                margin-bottom: $spacing-small;
                margin-left: $spacing-tiny;
                margin-right: $spacing-tiny;
                width: 50px;
            }
            &:first-child img{
                margin-left: 0;
            }
            &:last-child img{
                margin-left: 0;
            }
        }
        a{
            opacity: 1;
            transition: opacity 0.5s;
            &:hover{
                opacity: 0.5;
            }
        }
    }
}


#footer-bot-container{
    padding: $spacing-small inherit;
    .bottom-logo-bar{
        align-items: center;
        a{

            width: 250px;
            margin-right: $spacing-normal;
            @media screen and (max-width: 700px){
                margin-right: 0;
            }
            &:last-of-type{
                margin-right: 0;
                img{
                    transform: scale(0.75);//fix biba sizing
                }
            }
            img{
                max-height: 100px;
                width: auto;
                margin: auto;
            }
        }
        $spacer-border: transparentize($c-white, 0.8) 1px solid;
        .spacer{
            height: 120px;
            padding-right: $spacing-normal;
            border-left: $spacer-border;
        }

        @media screen and (max-width: 700px){
            flex-direction: column;
            .spacer{
                height: 1px;
                width: 120px;
                border: none;
                padding: 0;
                margin-bottom: $spacing-small;
                margin-top: $spacing-small;
                border-bottom: $spacer-border;
            }
            img{
                padding-right: 0;
            }
        }
    }
    .legal{
        @include font-body-small;
        font-weight: $font_weight-light;
        text-align: center;
        text-align: center;
        padding: $spacing-small $spacing-tiny;
    }
}






#footer-bot-container .bottom-logo-bar a img{
    max-width:150px;
}

#footer-bot-container{
    background-color: #111f25;
}

.bottom-logo-bar-wrapper{
    padding: 40px 0 30px;
}

#footer-bot-container .legal{
    border-top: rgba(255, 255, 255, 0.2) 1px solid;
}

#footer-bot-container .legal{
    font-size: 10pt;
}

#footer-top-container .products-list ul{
    padding-top: 40px;
    padding-bottom: 40px;
}
