.testimonial-split-content{
    position: relative;
    top: -150px;
    .background{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        .top,
        .bottom{

        }
        .top{
            flex: 0 0 150px;
        }
        .bottom{
            flex: 1 0 1px;
            background-color: $c-light_grey;
        }
    }
    .foreground{
        position: relative;
        z-index: 2;
        text-align: center;
        .content-width{

            padding: $spacing-normal $spacing-huge;
            background-color: $c-white;
            @media screen and (max-width: 800px){
                padding-left: $spacing-tiny;
                padding-right: $spacing-tiny;
            }
        }
        .meta{
            @include font-tiny_text;
            color: $c-black;
            margin-bottom: $spacing-tiny;
        }
        .title h1{
            @include font-thin_heading;
            font-weight: 300;
            margin-bottom: $spacing-small;
        }
        .content{
            @include user_input_area;
            display: flex;
            flex-direction: row;
            justify-content: center;
            .image{
                flex: 0 0 50%;

            }
            .text{
                text-align: left;
                flex: 0 0 100%;
                .padding-wrapper{
                    padding-left: $spacing-small;
                }
            }
        }
    }
    .back-button-container{
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: -150px;
        background-color: $c-light_grey;

        $whitespace-height: 20px;
        .whitespace{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background-color: $c-white;
            height: $whitespace-height;
            padding: 0;
        }
        .bn-button{
            position: relative;
            top: ($whitespace-height / 2);
        }
    }
}
